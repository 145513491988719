@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url("../../fonts/Poppins-500.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.home-item-edit {
    min-width: 400px;
    min-height: 200px;
}

.home-green {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.home-section1 {
    padding: 50px 0;
}
  
.home-section1-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.home-section1-text-content {
    color: black;
    max-width: 55%;
    padding-left: 5%;
}

.home-section1-text-content h1 {
    font-size: 62px;
    margin-bottom: 40px;
    font-family: "PoppinsBold";
}

.home-section1-text-content p {
    font-size: 18px;
    font-family: "PoppinsSemiBold";
    margin-bottom: 100px;
}

.home-section1-buttons {
    display: flex;
    gap: 30px;
}

.home-section1-btn {
    padding: 15px 25px;
    border: none;
    border-radius: 77px;
    cursor: pointer;
    font-size: 16px;
    font-family: "PoppinsBold";
    width: 270px;
}

.home-section1-btn {
    background: linear-gradient(to right, #4A64AF, #91B3E0);
    color: #fff;
}

.home-section1-image-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    height: 600px;
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 1s;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 1s;
}


.home-section1-vector {
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
    z-index: 2;
}

.home-section1-vector2 {
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
    z-index: 3;
}

.home-section1-image-content img {
    height: 100%;
}

.home-section1-background {
    z-index: 1000;
}

.home-section1-info-card {
    position: absolute;
    bottom: 30px;
    left: 40%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 40px 70px;
    border-radius: 28px;
    text-align: center;
    z-index: 2000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.home-section1-info-card p {
    margin: 0;
    font-size: 19px;
    font-family: "PoppinsSemiBold";
}

.home-section1-btn-card {
    background: linear-gradient(to right, #4A64AF, #91B3E0);
    color: #fff;
    font-size: 14px;
    font-family: "Bold";
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 77px;
    cursor: pointer;
}

/* Section2 */
.home-section2 {
    padding: 50px 0;
}

.home-section2-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.home-section2-image-content {
    flex: 1;
    margin-right: 20px;
    display: flex;
    justify-content: center;
}

.home-section2-image-content img {
    width: 70%;
}

.home-section2-text-content {
    flex: 1;
    padding-right: 100px;
}

.home-section2-text-content h2 {
    font-size: 48px;
    font-family: "PoppinsBold";
    margin-bottom: 20px;
}

.home-section2-text-content p {
    font-size: 18px;
    font-family: "Poppins";
}

/* Section3 */
.home-section3 {
    padding: 50px 0;
}

.home-section3-container {
    display: flex;    
    margin: 0 auto;
    padding: 0 20px;
}

.home-section3 h2 {
    font-size: 40px;
    font-family: "PoppinsBold";
    margin-bottom: 20px;
    padding-top: 100px;
    flex: 1;
    text-align: center;
}

.home-section3-services {
    display: flex;
    gap: 20px;
    flex: 1;
}

.home-section3-left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
}

.home-section3-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.home-section3-service {
    padding: 20px;
    background-color: #f9f9f9;
    text-align: left;
    transition: background-color 0.3s ease;
    position: relative;
}

.home-section3-service::before {
    content: '';
    position: absolute;
    top: 20px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: #33CB98;
    border-radius: 50%;
}

.home-section3-service--hovered {
    background: linear-gradient(45deg, #4A64AF, #91B3E0);
    color: #fff;
}

.home-section3-service--hovered::before {
    background-color: #33CB98;
}

.home-section3-service h3 {
    font-size: 24px;
    font-family: "PoppinsSemiBold";
    margin-bottom: 10px;
}

.home-section3-service p {
    font-size: 16px;
    font-family: "Poppins";
    line-height: 1.6;
}

.home-section3-service--default {
    background-color: #4A64AF;
    color: #fff;
}

.home-section3-service--default::before {
    background-color: #33CB98;
}

/* Section 4 */
.home-section4 {
    margin-top: 100px;
    padding: 50px 0;
}

.home-section4-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.home-section4-image-content {
    flex: 3;
    margin-right: 20px;
    display: flex;
    justify-content: center;
}

.home-section4-image-content img {
    width: 80%;
}

.home-section4-text-content {
    flex: 2;
    padding-right: 100px;
}

.home-section4-text-content h2 {
    font-size: 40px;
    font-family: "PoppinsBold";
    margin-bottom: 20px;
}

.home-section4-list {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    font-size: 20px;
    font-family: "Poppins";
}

.home-section4-list li {
    margin-bottom: 10px;
}

.home-section4-list li strong {
    font-weight: bold;
}

/* Section 6 */
.home-section6-container {
    padding: 50px;
    border-radius: 10px;
    margin: 0 auto;
    background-color: #fff;
    margin-bottom: 50px;
}

.home-section6-content h2 {
    font-size: 40px;
    font-family: "PoppinsBold";
    margin-bottom: 20px;
    text-align: center;
}

.home-section6-content h3 {
    font-size: 16px;
    font-family: "PoppinsBold";
    margin-bottom: 10px;
}

.home-section6-content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
}

.home-section6-content ul li {
    margin-bottom: 10px;
    font-size: 16px;
}

.home-section6-content p {
    font-size: 16px;
    font-family: "Poppins";
    margin-bottom: 20px;
    line-height: 1.6;
}

/* Section 7 */
.home-section7-container {
    background: linear-gradient(45deg, #4A64AF, #83A4D6);
    padding: 50px;
    color: #fff;
}

.home-section7-content {
    margin: 0 auto;
    text-align: left;
}

.home-section7-content h2 {
    font-size: 40px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "PoppinsBold";
}

.home-section7-content p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.6;
    font-family: "Poppins";
}

.home-section7-content ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin-bottom: 20px;
}

.home-section7-content ol li {
    margin-bottom: 10px;
    font-size: 16px;
    font-family: "Poppins";
}

  /* Section8 */
.home-section8 {
    padding: 50px 0;
}

.home-section8-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.home-section8-image-content {
    flex: 3;
    margin-right: 20px;
    display: flex;
    justify-content: center;
}

.home-section8-image-content img {
    width: 80%;
}

.home-section8-text-content {
    flex: 2;
    padding-right: 100px;
}

.home-section8-text-content h2 {
    font-size: 40px;
    font-family: "PoppinsBold";
    margin-bottom: 20px;
}

.home-section8-text-content p {
    font-size: 20px;
    font-family: "Poppins";
}
  

@media (max-width: 1199px) {
    .home-section1-container {
        flex-direction: column;
    }

    .home-section1-text-content {
        color: black;
        max-width: 100%;
        width: 80%;
    }

    .home-section1-text-content h1 {
        font-size: 30px;
    }

    .home-section1-image-content {
        display: none;
    }

    .home-section2 {
        padding: 0;
    }

    .home-section2-container {
        flex-direction: column;
    }

    .home-section2-text-content h2 {
        font-size: 30px;
    }

    .home-section2-text-content {
        padding: 30px;
    }

    .home-section3 {
        padding: 0px;
    }

    .home-section3 h2 {
        font-size: 30px;
        margin-bottom: 20px;
        padding-top: 0px;
        flex: 1;
        text-align: center;
    }

    .home-section3-services {
        flex-direction: column;
    }

    .home-section3-container {
        flex-direction: column;
    }

    .home-section3-right {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .home-section3-right .home-section3-service {
        width: calc(100% - 40px);
    }

    .home-section4 {
        margin-top: 0;
    }

    .home-section4-container {
        flex-direction: column;
    }

    .home-section4-text-content {
        padding-right: 0;
        padding: 30px;
    }

    .home-section4-text-content h2 {
        font-size: 30px;
    }

    .home-section6-content h2 {
        font-size: 30px;
    }

    .home-section6-container {
        margin-bottom: 0;
        padding: 30px;
    }

    .home-section6-container h3 {
        font-size: 16px;
    }

    .home-section7-container {
        padding: 30px;
    }

    .home-section7-content h2 {
        font-size: 30px;
    }

    .home-section8 {
        padding: 30px;
    }

    .home-section8-container {
        flex-direction: column;
    }

    .home-section8-image-content {
        margin-right: 0;
    }

    .home-section8-text-content {
        padding-right: 0;
    }

    .home-section8-text-content h2 {
        font-size: 30px;
    }
}
  