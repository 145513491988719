@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.faq-container-green {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.faq-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.faq-container h2 {
    font-size: 40px;
    flex: 1;
    color: #BADDDB;
    font-family: "PoppinsBold";
}

.faq-container h2 span {
    color: black;
}

.faq-item-container {
    max-width: 60%;
}

.faq-item {
    border: 1px solid #585858;
    margin-bottom: 20px;
    padding: 10px;
    cursor: pointer;
    align-self: flex-end;
    max-width: 600px;
    border-radius: 5px;
}

.faq-question {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 10px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: "PoppinsBold";
}

.faq-icon {
    font-size: 24px;
}

.faq-answer {
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    font-family: "Poppins";
}

.faq-edit-button {
    display: inline-block;
    padding: 3px 40px;
    margin-top: 20px;
    background-color: #ACB9A5;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 20px;
    border: none;
}

.faq-text-edit-input {
    width: 85%;
    font-size: 16px;
    margin-left: 60px;
    height: 50px;
    color: black;
}

/* Mobile ---------------------------- */
@media (max-width: 1199px) { 
    .faq-container {
        flex-direction: column;
    }
    
    .faq-container h2 {
        font-size: 30px;
    }

    .faq-item-container {
        max-width: 100%;
    }
    
    .faq-question {
        font-size: 16px;
    }    
}