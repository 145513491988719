@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.footer-container {
  background: linear-gradient(to top, #1F2A49, #4A64AF);
  padding: 50px 20px;
  color: #fff;
}

.footer-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-brand h2 {
  font-size: 36px;
  font-family: "PoppinsBold";
  margin-bottom: 30px;
  text-align: center;
}

.footer-sections {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;    
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  padding-left: 5%;
}

.footer-section h3 {
  font-size: 24px;
  font-family: "PoppinsBold";
  margin-bottom: 15px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
  font-size: 24px;
  font-family: "Poppins";
}

.footer-section p {
  font-size: 24px;
  font-family: "Poppins";
  margin-bottom: 10px;
}

.footer-link {
  text-decoration: none;
  color: inherit; 
}

@media (max-width: 599px) {
  .footer-section {
      order: 2;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 0;
      text-align: center;
  }

  .footer-section.contact {
      order: 1;
  }
}
  