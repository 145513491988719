
.admin {
    margin: 0 auto;
    padding-bottom: 30px;
    display: flex;
}

.admin-connexion {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.admin-form {
    display: flex;
    flex-direction: column;
    padding: 2% 0;
}

.admin-form-control {
    position: relative;
    margin-bottom: 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    font-family: "Neris";
    font-size: 14px;
    color: #1B1446;
}

.admin-form-control input {
    border: none;
    background-color: white;
    border-radius: 30px;
    border: 1px solid #EEEEEE;
    padding: 10px;
    width: 100%;
    height: 30px;
    padding-left: 40px;
    margin-left: 30px;
}

.admin-form-control input[type="password"] {
    -webkit-text-security: disc;
}

.admin-form-control label {
    margin-bottom: 10px;
    align-self: flex-start;
    text-align: left;
}

.admin-button {
    display: inline-block;
    padding: 20px 80px;
    margin-top: 20px;
    background-color: #4A64AF;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-size: 18px;
    border: none;
    width: 100%;
}

.admin-button:hover {
    background-color: #4A64AF;
}