@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url("../../fonts/Poppins-500.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.traitement-item-edit {
    min-width: 400px;
    min-height: 200px;
}

.traitement-green {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.traitement-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 100px;
}

.traitement-content {
    max-width: 50%;
}

.traitement-title {
    display: inline-block;
    position: relative;
    padding: 10px 0;
    font-size: 62px;
    font-family: 'PoppinsBold';
}

.title-line {
    display: block;
    position: relative;
    z-index: 1;
    line-height: 1.2;
}

.line-1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E6F4F1;
    border-radius: 15px 15px 0 0;
    z-index: -1;
    margin: -10px 35px -10px -20px;
}

.line-2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E6F4F1;
    border-radius: 15px;
    z-index: -1;
    padding: 10px 20px;
    margin: -10px -20px;
}

.traitement-content p {
    font-size: 18px;
    margin-top: 20px;    
    font-size: 18px;
    font-family: 'Poppins';
}

.traitement-image-container {
    position: relative;
    max-width: 50%;
}

.traitement-image {
    width: 100%;
    border-radius: 10px;
}

.traitement-button {
    position: absolute;
    bottom: 20px;
    right: 0px;
    background: linear-gradient(to right, #4A64AF, #91B3E0);
    color: white;
    border: none;
    font-size: 14px; 
    border-radius: 77px;
    font-family: "PoppinsBold";
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.traitement-button:hover {
    background-color: #0056b3;
}

/* Sous section*/ 
.traitement-nav {
    display: flex;
    justify-content: space-around;
    background-color: #F4F4F4;
    padding: 10px 0;
    margin-top: 20px;
}

.traitement-nav-item {
    cursor: pointer;
    padding: 10px 20px;
    color: #878787;
    transition: color 0.3s;
    font-size: 30px;
    font-family: "PoppinsSemiBold";
    text-align: center;
}

.traitement-nav-item.active {
    color: #4A64AF;
}

.nav-line {
    display: block;
}

.traitement-subsection {
    padding: 20px 0px;
    background-color: #ffffff;
}

@media (max-width: 1199px) {
    .traitement-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px;
    }

    .traitement-nav {
        display: none;
    }

    .traitement-image-container {
        display: none;
    }

    .traitement-content {
        max-width: 100%;
    }
    

    .traitement-title {
        display: inline-block;
        position: relative;
        padding: 10px 0;
        font-size: 40px;
        text-align: center;
    }

    .line-1::before {
        display: none;
    }
    
    .line-2::before {
        display: none;
    }


}