@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.reviews-wrapper-green {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.reviews-wrapper {
    padding-bottom: 100px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.reviews-wrapper h2 {
    font-family: "PoppinsBold";
    font-size: 42px;
    color: #525252;
}

.reviews-section {
    padding: 50px 0;
    padding-bottom: 100px;
    background-color: #f9f9f9;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reviews-slider-container {
    width: 85%;
}

.reviews-section h2 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
}

.reviews-card-wrapper {
    padding: 0 10px;
    box-sizing: border-box;
}

.reviews-card {
    background: white;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.144);
    padding: 20px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reviews-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.reviews-card-initial {
    background-color: #4A64AF;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.reviews-card-info h3 {
    margin: 0;
    font-size: 18px;
}

.reviews-card-rating span {
    color: #FFA500;
}

.reviews-card-date {
    font-size: 12px;
    color: #999;
}

.reviews-card-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.reviews-card-link {
    background: none;
    border: none;
    color: #4A64AF;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
    text-decoration: none;
}

.reviews-card-link:hover {
    text-decoration: underline;
}

.slick-dots li button:before {
    font-size: 12px;
    color: #000;
}

.slick-dots li.slick-active button:before {
    color: #4A64AF;
}
