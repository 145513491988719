.review-section {
    padding: 50px 0;
    padding-bottom: 100px;
    background-color: #f9f9f9;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.review-slider-container {
    width: 85%;
}

.slick-dots {
    bottom: -50px; 
}


.review-section h2 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
}

.review-card-wrapper {
    padding: 0 80px;
    box-sizing: border-box;
}

.review-card {
    background: white;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.144);
    padding: 20px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.review-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.review-card-initial {
    background-color: #4A64AF;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.review-card-info h3 {
    margin: 0;
    font-size: 18px;
}

.review-card-rating span {
    color: #FFA500;
}

.review-card-date {
    font-size: 12px;
    color: #999;
}

.review-card-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.review-card-link {
    background: none;
    border: none;
    color: #4A64AF;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
    text-decoration: none;
}

.review-card-link:hover {
    text-decoration: underline;
}

.slick-dots li button:before {
    font-size: 12px;
    color: #000;
}

.slick-dots li.slick-active button:before {
    color: #4A64AF;
}
  
.slick-slide {
    padding-left: 0px;
}

.slick-prev:before, .slick-next:before {
    color: black;
}

.slick-prev {
    left: -25px; 
    z-index: 1;
}

.slick-next {
    right: -25px
}

@media (max-width: 1199px) {
    .review-slider-container {
        width: 85%;
    }

    .review-card-wrapper {
        padding: 0 30px;
        box-sizing: border-box;
    }
}