@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url("../../fonts/Poppins-500.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.cabinet-item-edit {
    min-width: 400px;
    min-height: 200px;
}

.cabinet-green {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.cabinet-section1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cabinet-section1 h2 {
    font-size: 36px;
    font-family: "PoppinsSemiBold";
    text-align: left;
    align-self: start;
    padding-left: 100px;
}

.cabinet-slide-container {
    width: 95%;    
}

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide img {
    padding-left: 10%;
    width: 70%;
    height: auto;
}

.full-size-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Section2 */
.cabinet-section2 {
    text-align: center;
    padding: 50px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cabinet-section2 h2 {
    font-size: 36px;
    font-family: "PoppinsSemiBold";
    text-align: left;
    align-self: start;
    padding-left: 100px;
}

.cabinet-section2-intro {
    font-size: 20px;
    margin-bottom: 40px;
}

.cabinet-section2-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 60%;
    border-radius: 10px;
}

.cabinet-section2-member {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 50px;
}

.cabinet-section2-member2 {
    display: flex;
    flex-direction: row-reverse;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 50px;
}

.cabinet-section2-image {
    width: 50%;
    object-fit: cover;
}

.cabinet-section2-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cabinet-section2-info h2 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 20px;
    font-family: "PoppinsSemiBold";
    color: #4A64AF;
}

.cabinet-section2-info p {
    margin: 15px;
    font-size: 15px;
    font-family: "Poppins";
    color: #3B3C4A;
    text-align: left;
}

.cabinet-section2-appointment-button {
    background: linear-gradient(to right, #4A64AF, #91B3E0);
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 77px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: auto;
    font-size: 16px;
    font-family: "PoppinsBold";
}

.cabinet-section2-appointment-button:hover {
    background-color: #0056b3;
}
  
@media (max-width: 1199px) {
    .cabinet-section2-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0px;
        max-width: 100%;
    }

    .cabinet-section2-member {
        flex-direction: column;
    }
    
    .cabinet-section2-member2 {
        flex-direction: column;
    }

    .cabinet-section2-image {
        width: 100%;
    }

    .cabinet-section2-info h2 {
        padding-left: 20px;
        margin-bottom: 0;
    }
}
  
