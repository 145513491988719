@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url("../../fonts/Poppins-500.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.blog-section {
    font-family: "Poppins";
    margin-bottom: 100px;
}

.blog-section h2 {
    font-family: "PoppinsBold";
}

.blog-section-title {
    padding-left: 15%;
    font-size: 40px;
}

.blog-section-articles {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.blog-section-article {
    width: 25%;
    padding: 20px;
    border-radius: 5px;
}

.blog-article-link {
    position: relative;
    display: block;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-article-image-container {
    position: relative;
}

.blog-article-date-container {
    position: absolute;
    top: 77%;
    left: 0px;
    background-color: white;
    padding: 5px;
    transform: rotate(-90deg);
    transform-origin: top left;
    white-space: nowrap;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.blog-article-date-container p {
    margin-bottom: 0;
    margin-top: 0;
}

.blog-section-article h2{
    font-family: "PoppinsBold";
    font-size: 30px;
}

.blog-section-article img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.blog-section-article-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.blog-section-article-details strong {
    font-family: "PoppinsBold";
    font-size: 24px;
}

.blog-section-article-details p {
    font-size: 18px !important;
}

.blog-section-article-point {
    width: 5px !important;
    margin: 0 5px;
    padding-bottom: 2px;
}

.blog-section-article-share {
    height: 10px !important;
    width: 10px !important;
    padding-top: 0;
}

.blog-section-article p {
    font-size: 20px;
}

.blog-section-article-details img {
    width: 25px;
    border-radius: 50%;
}

.blog-section-article-bottom {
    font-size: 18px;
}

.blog-section-article-bottom a {
    font-size: 24px;
    font-family: "PoppinsBold";
    color: inherit;
}

.blog-section-show-more-container {
    text-align: center;
    padding: 50px 0;
}

.blog-section-show-more {
    display: inline-block;
    padding: 10px 40px; 
    background-color: #FFFFFF; 
    color: #121416;
    border: 1px solid blakc;
    border-radius: 3px;
    cursor: pointer;
    font-size: 20px;
    font-family: "PoppinsBold";
    transition: background-color 0.3s ease;
}

.blog-section-show-more:hover {
    background-color: #ececec;
}

@media (max-width: 1199px) {
    .blog-section-title {
        padding-left: 5%;
        font-size: 30px;
    }

    .blog-section-articles {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .blog-section-article {
        width: 80%;
    }
}