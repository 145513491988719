@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url("../../fonts/Poppins-500.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.sport-item-edit {
    min-width: 400px;
    min-height: 200px;
}

.troubles-sport-section1 {
    padding: 50px 100px;
    background-color: #ffffff;
}

.troubles-sport-section1 h1 {
    font-size: 48px;
    text-align: center;
    font-family: 'PoppinsBold';
    margin-bottom: 60px;
    color: #000000;
}

.troubles-sport-section1 p {
    font-size: 19px;
    font-family: 'Poppins';
    margin-bottom: 40px;
}

/* Section2 */
.troubles-sport-section2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 100px;
}

.sport-section2-content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.sport-section2-image {
    max-width: 50%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sport-section2-text {
    max-width: 45%;
    margin-left: 20px;
}

.sport-section2-text h2 {
    font-size: 19px;
    font-family: 'PoppinsBold';
    margin-bottom: 20px;
    color: #000000;
}

.sport-section2-text p {
    font-size: 19px;
    margin-bottom: 10px;
}

/* Button */
.sport-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;
}

.sport-button {
    background: linear-gradient(to right, #4A64AF, #91B3E0);
    color: white;
    border: none;
    font-size: 16px; 
    border-radius: 77px;
    font-family: "PoppinsBold";
    padding: 15px 30px;
    cursor: pointer;    
}

/* Section3 */
.troubles-sport-section3 {
    padding: 50px 100px;
    background-color: #ffffff;
}
  
.troubles-sport-section3 h2 {
    font-size: 19px;
    font-family: 'PoppinsSemiBold';
    margin-bottom: 40px;
    color: #000000;
}

.troubles-sport-section3 p {
    font-size: 19px;
    margin-bottom: 40px;
    font-family: 'Poppins';
}

.troubles-sport-background {
    width: 100%;
    margin-bottom: 40px;
}

/* Section4 */
.troubles-sport-section4 {
    padding: 50px 100px;
}

.troubles-sport-section4 h2 {
    font-size: 19px;
    font-family: 'PoppinsSemiBold';
    margin-bottom: 40px;
    color: #000000;
}

.troubles-sport-section4 p {
    font-size: 19px;
    font-family: 'Poppins';
    margin-bottom: 40px;
}

@media (max-width: 1199px) {
    .troubles-sport-section1 {
        padding: 30px;
    }

    .troubles-sport-section1 h1 {
        font-size: 19px;
        margin-bottom: 30px;
    }

    .troubles-sport-section1 p {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .troubles-sport-section2 {
        padding: 0;
    }

    .sport-section2-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sport-image {
        max-width: 100%;
    }

    .sport-section2-text {
        max-width: 100%;
        padding: 30px;
        margin-left: 0;
    }

    .sport-button-container {
        margin-bottom: 0px;
    }

    .troubles-sport-section3 {
        padding: 30px;
    }

    .troubles-sport-section3 h2 {
        margin-bottom: 15px;
    }

    .troubles-sport-section3 p {
        font-size: 16px;
    }

    .troubles-sport-background {
        height: 150px;
        margin: 0;
    }

    .troubles-sport-section4 {
        padding: 30px;
    }

    .troubles-sport-section4 h2 {
        margin-bottom: 15px;
    }

    .sport-button-container {
        margin-top: 0px;
        margin-bottom: 0;
    }
}