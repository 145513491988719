@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.article-left-container img {
    width: 100%;
}

.article-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins";
}


  
.article-header-image {
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "PoppinsBold";
    color: black;
}

.article-header-image h1 {
    font-size: 36px;
    text-align: center;
    max-width: 65%;
}
  
.article-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.article-content.dark {
    background-color: black;
    color: white;
}

.article-content.light {
    background-color: white;
    color: black;
}
  
.article-left-container {
    padding: 40px;
    padding-top: 0;
    max-width: 65%;
    box-sizing: border-box;    
}

.article-left-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.article-follow-container, .article-newsletter-container, .article-share-container {
    border: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.article-follow-container {
    padding-top: 0;
}

.article-follow-container h2, .article-share-container h2 {
    font-size: 25px;
    font-family: "PoppinsBold";
    margin-top: 0;
}

.article-icons {
    display: flex;
    justify-content: left;
    gap: 25px;
    padding: 0px 0px;
}
  
.article-icon a img{
    width: 20px;
    height: 20px;
}

.article-newsletter-container {
    padding-top: 0;
    padding-bottom: 0;
}

.article-newsletter-container p {
    font-family: "Poppins";
    font-size: 20px;
}

.article-checkbox-container {
    color: #8a8a8a;
    font-size: 16px;
}

.article-newsletter-container input[type="checkbox"] {
    margin-top: 10px;
    margin-left: 0;
    margin-right: 5px;
    margin-bottom: 0;
}

.article-newsletter-input {
    justify-content: left;
    display: flex;
    align-items: center;
    gap: 0;
    margin-top: 0px;
}

.article-newsletter-input input[type="email"] {
    padding: 10px 5px;
    width: 100%;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    background-color: white;
    border: 1px solid black;
    color: black;
    transition: background-color 0.3s ease;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}


.article-newsletter-input input[type="email"]::placeholder {
    color: rgb(92, 92, 92);  
}

.article-newsletter-input button {
    padding: 10px 15px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    background-color: #000E15;
    border: 1px solid black;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.article-newsletter-input button:hover {
    background-color: #002530;
}

.article-newsletter-input button:focus {
    outline: none;
}

.article-author-container {
    border: none;    
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;    
}

.article-author-container.dark {
    background-color: rgb(34, 34, 34);
    color: white;
}

.article-author-container.light {
    background-color: #EBEBEB;
    color: #121416;
}
  
.article-author-container h2 {
    font-size: 18px;
    font-weight: 100;
    margin-bottom: 0; 
}

.article-author-name {
    font-size: 20px;
    font-weight: 500;
    margin-top: 0;
}

.article-author-container p {
    font-size: 18px;
    color: #8a8a8a;
}

@media (max-width: 599px) {
    .article-content {
        display: flex;
        flex-direction: column;
    }

    .article-author-container {
        order: -1;
    }
}

@media (max-width: 1199px) {
    .article-page {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .article-page.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }

    .article-header-image h1 {
        font-size: 20px;
        max-width: 90%;
        margin-top: 100px;
    }

    .article-left-container {
        padding: 40px;
        padding-top: 0;
        max-width: 100%;
        box-sizing: border-box;    
    }

    .article-left-container img {
        max-width: 100%;
        height: 100%;
    }
}