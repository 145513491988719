@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

.navbar-desktop {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    background-color: transparent; 
    padding: 40px 0px;
    border-bottom: none; 
    z-index: 2000;
}

.navbar-logo {
    display: flex;
    align-items: center;
}

.navbar-logo-image {
    vertical-align: middle;
    max-width: calc(100vh / 5);
    margin-left: 20px;
}

.navbar-center-links {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "PoppinsBold";
    font-size: 14px;
}

.navbar-link {
    margin: 0 25px;
    text-decoration: none;
    color: black;
}

.navbar-link.active {
    color: #4A64AF;
}

.navbar-link-button {
    margin-right: 20px;
    padding: 20px 20px;
    background: linear-gradient(to right, #4A64AF, #91B3E0);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px; 
    border-radius: 77px;
    font-family: "PoppinsBold";
}

@media (max-width: 1640px) and (min-width: 1450px) { 
    .navbar-desktop {
        height: 7%;
    }

    .navbar-center-links {
        font-size: 20px;
    }

    .navbar-link-button {
        margin-right: 15px;
        padding: 15px 15px;
        font-size: 20px; 
    }
}

@media (max-width: 1449px) and (min-width: 700px) { 
    .navbar-desktop {
        height: 7%;
    }

    .navbar-center-links {
        font-size: 18px;
    }

    .navbar-link-button {
        margin-right: 15px;
        padding: 12px 12px;
        font-size: 18px; 
    }
}

@media (max-width: 1199px) {
    body.menu-open {
        position: fixed;
        width: 100%;
        top: calc(-1 * var(--scroll-y));
    }
    
    .navbar-desktop {
        display: none;
    }

    .navbar-mobile {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        background-color: transparent;
        transition: opacity 0.5s ease;
        font-family: "PoppinsBold";
        font-size: 18px;
    }

    .navbar-menu-icon {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 2;
        display: inline-flex;
        background-color: none;
        cursor: pointer;
        width: 100px;
        height: 100px;
    }

    .navbar-hamburger-icon {
        display: inline-flex;
        width: 65%;
        height: 65%;  
        margin-right: 5px;
        fill: #ACB9A5;
    }

    .navbar-hamburger-icon.active {
        display: inline-flex;
        width: 65%;
        height: 65%;  
        margin-right: 5px;
        fill: black;
    }
    
    .navbar-sidebar {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        z-index: 1;
        left: -100vw;
        width: 100vw;
        height: 100%;
        color: white;
        background-color: #4A64AF;
        transition: left 0.3s ease;
    }

    .navbar-sidebar.active {
        overflow: hidden;
        transform: translateX(100%);
        transition: transform 0.3s ease;
    }

    .navbar-sidebar-link {
        display: inline-block;
        padding: 10px;
        padding-bottom: 20px;
        text-decoration: none;
        color: inherit;
        font-size: 36px;
    }

    .navbar-sidebar-link-sub {
        display: inline-block;
        padding-top: 0px;
        padding-bottom: 15px;
        text-decoration: none;
        color: inherit;
        font-size: 16px;
        font-family: "Poppins";
    }

    .navbar-sidebar-link:first-child {
        margin-top: 40px;
    }

    .navbar-mobile-language-container {
        display: flex;
        flex-direction: row;
    }

    .navbar-mobile-language-option {
        cursor: pointer;
        margin-right: 20px;
    }

    .navbar-mobile-language-option img {
        border-radius: 4px;
    }

    .navbar-hamburger-icon {
        fill: #4A64AF;
    }
}
